import * as React from 'react'
import { Container } from './Container'
import Link from 'next/link'

export const PreviewAlert = () => {
  return (
    <div className="w-screen border-black bg-yellow-500">
      <Container>
        <div className="py-2 text-center text-sm">
          This is a page preview{' '}
          <Link href={'/api/exit-preview'} as={{ pathname: `/api/exit-preview` }}>
            Click here
          </Link>{' '}
          to exit preview
        </div>
      </Container>
    </div>
  )
}
