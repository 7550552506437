import { useEffect, useState } from 'react'
import {
  addIndex,
  always,
  chain,
  cond,
  equals,
  fromPairs,
  head,
  isEmpty,
  isNil,
  map,
  pipe,
  reject,
  T,
  trim,
  toPairs,
  type,
  compose,
  replace,
  reduce,
  concat,
  anyPass,
} from 'ramda'

export const normalizePhone = (value: any) => {
  if (!value) {
    return value
  }
  let onlyNums = value.replace(/[^\d]/g, '')
  if (onlyNums.match(/^1/)) {
    onlyNums = onlyNums.substr(1)
  }
  if (onlyNums.length <= 3) {
    return onlyNums
  }
  if (onlyNums.length <= 6) {
    return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3)}`
  }
  return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3, 6)}-${onlyNums.slice(6, 10)}`
}

export const useQueryString = () => {
  const [queryString, setQueryString] = useState('')
  useEffect(() => {
    if (process.browser) {
      setQueryString(window.location.search)
    } else {
      setQueryString(window.location.search)
    }
  }, [])
  return queryString
}

export const mapIndexed = addIndex(map)

export const ensureEndsWithSlash = (path: string) => {
  return path.endsWith('/') ? path : `${path}/`
}

export const removeQueryString = (s: string) => {
  return head(s.split('?'))
}

export const logWrapPromise = async (promise: Promise<any>) => {
  try {
    await promise
  } catch (e) {
    console.error({ e })
  }
}

export const getSourceFromPathname = (pathname: string) =>
  cond([
    [equals('/fb'), always('Facebook')],
    [equals('/streaming'), always('Streaming')],
    [equals('/sem'), always('Google SEM')],
    [equals('/tv'), always('tv')],
    [T, always('')],
  ])(pathname)

export const getSourceFromUTM = (sourceMedium: string) =>
  cond([
    [equals('google / cpc'), always('Google SEM')],
    [equals('google / GMBlisting'), always('GMBlisting')],
    [equals('fb / cpc'), always('Facebook')],
    [equals('email / manual'), always('Email Campaign')],
    [equals('kvue / display'), always('KVUE Display')],
    [T, always('')],
  ])(sourceMedium)

export async function sendGAEvent(category: string, action: string, label: string) {
  if (window['dataLayer']) {
    await window['dataLayer'].push({ event: `ga-${category}`, action, label })
  }
}

export const flattenObj = (obj: any) => {
  const go = (obj_) =>
    chain(([k, v]) => {
      if (type(v) === 'Object') {
        return map(([k_, v_]) => [`${k}.${k_}`, v_], go(v))
      } else {
        return [[k, v]]
      }
    }, toPairs(obj_))
  return fromPairs(go(obj))
}

export const unflatten = (obj) => {
  const result = {}
  for (const objectKey in obj) {
    const keys = objectKey.split('.')
    keys.reduce(function (r, e, j) {
      return r[e] || (r[e] = isNaN(Number(keys[j + 1])) ? (keys.length - 1 === j ? obj[objectKey] : {}) : [])
    }, result)
  }
  return result
}

export const cleanObject = (obj) => pipe(flattenObj, reject(isNil), reject(isEmpty), unflatten)(obj)

export const camelCaseQueryParams = (s) => s.replace(/-./g, (x) => x[1].toUpperCase())

export const serializeLinkWithParams = (baseUrl: string, params: any): string => {
  return compose(
    concat(baseUrl),
    replace(/^&/, '?'),
    reduce((acc, val) => {
      const key = val[0]
      const value = val[1]
      if (Array.isArray(value)) {
        value.forEach((arrayItemValue) => {
          if (typeof arrayItemValue !== 'string') return
          acc = acc + `&${key}=${encodeURIComponent(trim(arrayItemValue))}`
        })
        return acc
      }

      if (typeof value !== 'string') return acc

      return acc + `&${key}=${encodeURIComponent(trim(value))}` // value is a string lets encode
    }, ''),
    toPairs, // => Array of Array K V
    reject(anyPass([isEmpty, isNil])) // &page=['page-name']
  )(params)
}

export function isLandingPage(urlPathname: string) {
  if (urlPathname === '/') return true // Home Page Landing Page Event
  const landingPagePaths = ['/sem/', '/cities/', '/get-quote/']
  const landingPage = landingPagePaths.findIndex((page) => {
    // if the page exists in the landing page paths return true
    return urlPathname.includes(page)
  })
  return landingPage !== -1
}