import * as React from 'react'
import { formatPhoneNumber } from './utils/string-utils'

export const CallUsNowBar: React.FC<{
  title?: string
  phoneNumber: string
}> = ({ title = 'Schedule A Brake Repair Or Get A Quote Now.', phoneNumber }) => {
  return (
    <section
      style={{
        backgroundColor: '#f04f23',
        paddingTop: '3%',
        paddingBottom: '3%',
      }}
    >
      <div className="text-center hidden sm:flex flex-row items-center justify-center flex-wrap">
        <h3 className="m-0" style={{ color: 'white', lineHeight: 1.5 }}>
          {title}
        </h3>
        <a href={`tel:${phoneNumber}`}>
          <button className="bg-transparent hover:bg-white hover:text-lime text-white border-white border-4 rounded p-3 ml-5 font-semibold">
            {formatPhoneNumber(phoneNumber)}
          </button>
        </a>
      </div>
      <div className="sm:hidden text-center px-4">
        <h3 style={{ color: 'white', lineHeight: 1.5 }}>{title}</h3>
        <a href={`tel:${phoneNumber}`}>
          <button className="bg-transparent hover:bg-white hover:text-lime text-white border-white border-4 rounded p-3 font-semibold">
            {formatPhoneNumber(phoneNumber)}
          </button>
        </a>
      </div>
    </section>
  )
}
