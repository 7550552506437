import React from 'react'
import classNames from 'classnames'

export const Container: React.FC<React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>> = ({
  children,
  className,
  ...divProps
}) => {
  const baseClass = 'max-w-screen-xl mx-auto lg:px-5 px-5'
  return (
    <div className={classNames(baseClass, className)} {...divProps}>
      {children}
    </div>
  )
}
