import { useRouter } from 'next/router'
import { dissoc, omit, keys } from 'ramda'
import { ParsedUrlQuery } from 'querystring'

export const useQueryParam = () => {
  const { query: withSlug } = useRouter()
  return dissoc<ParsedUrlQuery>('slug', withSlug)
}

export const formatQueryParams = (query) => {
  const pageRemoved = omit(['page'], query)
  return keys(pageRemoved)
    .reduce((prev, cur, i) => {
      const symbol = i === 0 ? '?' : '&'
      return `${prev.toString()}${symbol}${cur.toString()}=${pageRemoved[cur.toString()]}`
    }, '')
    .toString()
}
