import nubrakesLogo from '../../public/img/nubrakes-logo.svg'
import Image from 'next/image'
import React from 'react'
import phoneIcon from '../../public/img/phone-icon.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone } from '@fortawesome/free-solid-svg-icons'
import Link from 'next/link'
import { ParsedUrlQuery } from 'querystring'
import { useQueryParam } from '../hooks/useQueryParam'
import { formatPhoneNumber } from './utils/string-utils'

type Props = {
  phoneNumber: string
  phoneNumberTxt: string
}

const removeBuilderURLParam = (query) => {
  // we pass query to our nav links
  // builder sets the page property on query
  const { page, cities, ...cleanedQuery } = query
  // page removed from visible query
  page || cities ? 'cleaned from url' : 'no page' // appeasing eslint
  return cleanedQuery
}

function NavContents(props: { query: ParsedUrlQuery; phoneNumber: string; phoneNumberTxt: string }) {
  return (
    <>
      <div className="text-base lg:flex-grow">
        <hr className={'mt-4 mb-2 sm:hidden'} />
      </div>
      <div className="flex items-center">
        <div className="text-right pr-2 lg:flex lg:flex-col hidden" style={{ flex: '0 0 auto' }}>
          <div className="font-bold text-black leading-none text-sm">NEED HELP?</div>
          <div className="leading-none text-xs text-gray-500">Call us now:</div>
        </div>
        <Image className="lg:flex hidden" src={phoneIcon} alt={'Phone Icon'} />
        <a
          className="lg:px-2 text-2xl font-bold hover:text-primary text-black ml-2 sm:ml-0"
          style={{ flex: '0 0 auto' }}
          href={`tel:${props.phoneNumber}`}
        >
          {formatPhoneNumber(props.phoneNumber)}
        </a>
      </div>
    </>
  )
}

export const NavbarSimple: React.FC<Props> = ({ phoneNumber = '8558005629', phoneNumberTxt = '(855) 800-5629' }) => {
  const query = useQueryParam()

  return (
    <nav className="sticky top-0 flex items-center justify-between flex-wrap bg-white py-2 px-4 border-b navbar-wrapper">
      <div className="flex items-center text-white mr-6 cursor-pointer w-1/2 sm:w-1/3 md:w-1/4 lg:w-[210px]">
        <Link href={{ pathname: `/`, query: removeBuilderURLParam(query) }} passHref={true}>
          <a className="mt-2 px-0 md:px-2 max-w-[210px]">
            <Image alt="NuBrakes Logo" src={nubrakesLogo} priority={true} />
          </a>
        </Link>
      </div>
      <div className="flex items-center justify-between mt-1 lg:hidden hover:cursor-pointer">
        <a
          href={`tel:${phoneNumber}`}
          className="border border-gray-200 rounded flex justify-center items-center h-8 p-2"
        >
          <FontAwesomeIcon icon={faPhone} />
        </a>
      </div>

      <div className={'hidden lg:flex lg:flex-row lg:flex-grow lg:items-center lg:w-auto'}>
        <NavContents query={removeBuilderURLParam(query)} phoneNumber={phoneNumber} phoneNumberTxt={phoneNumberTxt} />
      </div>
    </nav>
  )
}
