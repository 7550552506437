import { config } from '@fortawesome/fontawesome-svg-core'
import React from 'react'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { pipe } from 'ramda'
import { ensureEndsWithSlash, removeQueryString } from '../util/util'
import { Navbar } from './Navbar'
import { CallUsNowBar } from './CallUsNowBar'
import Footer from './Footer'
import { PreviewAlert } from './PreviewAlert'
import { NavbarSimple } from './NavbarSimple'

config.autoAddCss = false

// const PHONE_NUMBER = '8558005629'

const Layout: React.FC<{
  showFooter?: boolean
  showGetQuote?: boolean
  title: string
  phoneNumber?: string
  phoneNumberTxt?: string
  preview?: boolean
  description?: string
  robots?: string
  ogImage?: string
  showNavBar?: boolean
  navBarVariant?: 'default' | 'simple'
  footerDisclaimer?: string
  children?: any
}> = ({
  children,
  showGetQuote = true,
  title,
  phoneNumber = '8558005629',
  phoneNumberTxt = '(855) 800-5629',
  showFooter = true,
  showNavBar = true,
  navBarVariant = 'default',
  preview = false,
  description = '',
  robots = 'index, follow',
  ogImage,
  footerDisclaimer,
}) => {
  const router = useRouter()
  const slug = pipe(removeQueryString, ensureEndsWithSlash)(router.asPath)

  return (
    <div>
      <Head>
        <title>{title}</title>
        <link rel="apple-touch-icon" href={'/nubrakes-icon@192x192.png'} />
        <link rel="mask-icon" href={'/img/safari-pinned-tab.svg'} color="#ff4400" />
        <link rel="manifest" href="/manifest.json" />
        <meta name="theme-color" content="#ffffff" />
        <meta property="og:type" content="business.business" />
        <meta property="og:url" content="/" />
        <meta name="description" content={description} />
        <meta property="og:image" content={ogImage ?? '/img/nubrakes-og-holder.png'} />
        <meta name="msvalidate.01" content="A92E5C3F3CD0F7D6AB9BA88C995E1CD4" />
        <meta name="facebook-domain-verification" content="qu0y9ps1iuqrt526ivxlvnrnor5dkg" />
        <link rel={'canonical'} href={`https://nubrakes.com${slug}`} />
        <meta name="robots" content={robots} />
      </Head>
      {showNavBar ? (
        navBarVariant === 'default' ? (
          <Navbar phoneNumber={phoneNumber} phoneNumberTxt={phoneNumberTxt} />
        ) : (
          <NavbarSimple phoneNumber={phoneNumber} phoneNumberTxt={phoneNumberTxt} />
        )
      ) : null}
      {preview && <PreviewAlert />}
      <div>{children}</div>
      {showGetQuote && <CallUsNowBar title={`Schedule A Brake Repair Or Get a Quote Now.`} phoneNumber={phoneNumber} />}
      {showFooter && <Footer phoneNumber={phoneNumber} disclaimer={footerDisclaimer} />}
    </div>
  )
}

export default Layout
