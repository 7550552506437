import React from 'react'
import Image from 'next/image'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookF } from '@fortawesome/free-brands-svg-icons/faFacebookF'
import { faTwitter } from '@fortawesome/free-brands-svg-icons/faTwitter'
import { faInstagram } from '@fortawesome/free-brands-svg-icons/faInstagram'
import { faSquare } from '@fortawesome/free-solid-svg-icons/faSquare'
import Link from 'next/link'
import dompurify from 'isomorphic-dompurify'
import { Container } from './Container'
import { useQueryParam } from '../hooks/useQueryParam'
import whiteLogo from '../../public/img/nubrakes-logo@white.svg'
import { formatPhoneNumber } from './utils/string-utils'

interface FooterProps {
  phoneNumber?: string
  disclaimer?: string
}
function Footer({ phoneNumber = '8558005629', disclaimer }: FooterProps) {
  const query = useQueryParam()
  return (
    <footer
      style={{
        paddingTop: disclaimer ? 0 : '5%',
        paddingBottom: '5%',
        backgroundColor: '#03182a',
        color: 'white',
      }}
    >
      {/*language=scss*/}
      <style jsx>{`
        a {
          color: white;
        }

        .social a {
          padding-right: 30px;
        }

        li {
          padding-bottom: 5px;
          padding-top: 5px;
        }

        .footer-col-title {
          text-transform: uppercase;
          font-size: 1rem;
          letter-spacing: 0.075rem;
          margin-bottom: 2rem;
          font-weight: 700;
          color: rgba(255, 255, 255, 0.6);
        }
      `}</style>
      <Container>
        {!!disclaimer && (
          <div className="py-6 md:py-9">
            <small className="text-center" dangerouslySetInnerHTML={{ __html: dompurify.sanitize(disclaimer) }} />
          </div>
        )}
        <div
          style={{
            borderBottom: '1px solid grey',
            paddingTop: disclaimer ? 0 : '20px',
            marginBottom: 40,
          }}
        />
        <div className="grid grid-cols-1 md:grid-cols-5 gap-4">
          <div className="pb-5">
            <div style={{ width: 150, height: 'auto' }}>
              <Image src={whiteLogo} alt={'NuBrakes Logo Outline'} />
            </div>
          </div>
          <div>
            <h4 className="footer-col-title">Company</h4>
            <ul className="footer-list" style={{ listStyleType: 'none', paddingLeft: 0 }}>
              <li>
                <Link href={{ pathname: `https://www.classet.org/org/nubrakes`, query }}>
                  <a>Become A Technician</a>
                </Link>
              </li>
              <li>
                <Link href={{ pathname: `/about-us/`, query }}>
                  <a>About Us</a>
                </Link>
              </li>
              <li>
                <Link href={{ pathname: `/reviews/`, query }}>
                  <a>Reviews</a>
                </Link>
              </li>
              <li>
                <Link href={{ pathname: `/blog/`, query }}>
                  <a>Blog</a>
                </Link>
              </li>
              <li>
                <Link href={{ pathname: `/gift-cards/`, query }}>
                  <a>Gift Cards</a>
                </Link>
              </li>
              <li>
                <Link href={{ pathname: `/contact/`, query }}>
                  <a>Contact Us</a>
                </Link>
              </li>
              <li>
                <Link href={{ pathname: `/warranty`, query }}>
                  <a>Nubrakes Warranty Policy</a>
                </Link>
              </li>
              <li>
                <a href="https://apply.workable.com/nubrakes">Careers</a>
              </li>
              <li>
                <Link href={{ pathname: `/privacy-policy`, query }}>
                  <a>Privacy Policy</a>
                </Link>
              </li>
            </ul>
          </div>
          <div>
            <h4 className="footer-col-title">About Our Services</h4>
            <ul className="footer-list" style={{ listStyleType: 'none', paddingLeft: 0 }}>
              <li>
                <Link
                  href={{
                    pathname: `/services/brake-drum-replacement/`,
                    query,
                  }}
                >
                  <a>Brake Drum Replacement</a>
                </Link>
              </li>
              <li>
                <Link href={{ pathname: `/services/brake-pad-replacement/`, query }}>
                  <a>Brake Pad Replacement</a>
                </Link>
              </li>
              <li>
                <Link
                  href={{
                    pathname: `/services/brake-rotor-replacement/`,
                    query,
                  }}
                >
                  <a>Brake Rotor & Disc Replacement</a>
                </Link>
              </li>
              <li>
                <Link
                  href={{
                    pathname: `/services/brake-shoe-replacement/`,
                    query,
                  }}
                >
                  <a>Brake Shoes Replacement</a>
                </Link>
              </li>
            </ul>
          </div>
          <div>
            <h4 className="footer-col-title">Get Service</h4>
            <ul className="footer-list" style={{ listStyleType: 'none', paddingLeft: 0 }}>
              <li>
                <Link href={{ pathname: `/cities/`, query }}>
                  <a>Cities</a>
                </Link>
              </li>
              <li>
                <Link href={{ pathname: `/financing/`, query }}>
                  <a>Financing</a>
                </Link>
              </li>
              <li>
                <Link href={{ pathname: `/pricing/`, query }}>
                  <a>Pricing</a>
                </Link>
              </li>
              <li>
                <Link href={{ pathname: `/fleet-service/`, query }}>
                  <a>Fleet Service</a>
                </Link>
              </li>
              <li>
                <Link href={{ pathname: `/brake-repair-near-me/`, query }}>
                  <a>Brake Repair Near Me</a>
                </Link>
              </li>
              <li>
                <Link href={{ pathname: `/get-quote/`, query }}>
                  <a>Schedule A Repair</a>
                </Link>
              </li>
            </ul>
          </div>
          <div>
            <h4 className="footer-col-title">Contact Our Brake Team</h4>
            <a style={{ fontSize: 24, fontWeight: 'bold' }} href={`tel:${phoneNumber}`}>
              {formatPhoneNumber(phoneNumber)}
            </a>
            <h4 className="footer-col-title pt-4">Connect</h4>
            <div className="social flex flex-row">
              <a title="facebook" href="https://facebook.com/nubrakes/">
                <span className="fa-layers fa-fw">
                  <FontAwesomeIcon icon={faSquare} color={'#3b5998'} transform={'grow-20'} />
                  <FontAwesomeIcon icon={faFacebookF} transform={'grow-11 down-2'} />
                </span>
              </a>
              <a title="twitter" href="https://twitter.com/nubrakes">
                <span className="fa-layers fa-fw">
                  <FontAwesomeIcon icon={faSquare} color={'#1dcaff'} transform={'grow-20'} />
                  <FontAwesomeIcon icon={faTwitter} transform={'grow-6'} />
                </span>
              </a>
              <a title="instagram" href="https://www.instagram.com/getnubrakes/">
                <span className="fa-layers fa-fw">
                  <FontAwesomeIcon icon={faSquare} color={'#E1306C'} transform={'grow-20'} />
                  <FontAwesomeIcon icon={faInstagram} transform={'grow-6'} />
                </span>
              </a>
            </div>
          </div>
        </div>
        <div
          style={{
            borderBottom: '1px solid grey',
            paddingTop: '20px',
            marginBottom: 40,
          }}
        />
        <Link href={{ pathname: `/site-map/`, query }}>
          <a>Site Map</a>
        </Link>
        <p style={{ marginTop: 20 }}>© 2023 NuBrakes Mobile Brake Repair Service</p>
      </Container>
    </footer>
  )
}

export default Footer
